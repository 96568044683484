export default {
    fhtn: {
        56: '0x87be0b856960f57fb0104ef0922cd1dacbd37f64',
        97: '0xa322d237e20e9554Fe84A60DAe3462E41F940162', // FHTN
    },
    masterChef: {
        56: '0xb329c22437568c6a8bc063b4dd306198a7567a96',
        97: '0x6e69abfe7ffa204d79ff71341d7664a9d1083a59',
    },
    wbnb: {
        56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
        97: '0xae13d989dac2f0debff460ac112a837c89baa7cd',
    },
    lottery: {
        56: '0x1D09408E4A7757273517465ccaC652A3205Bd80A',
        97: '0xC0208aC3f2a88e2f9d9A62868eB51C6Ab5cB54e0', // Lottery.sol 
    },
    lotteryNFT: {
        56: '0x596AaCF4D016056b35ED7f105827258af4e8723d',
        97: '0x6190a2946d03fd56b0825f51bd995f74dca1676b', // LotteryNFT.sol 
    },
    mulltiCall: {
        56: '0x1ee38d535d541c55c9dae27b12edf090c608e6fb',
        97: '0x67ADCB4dF3931b0C5Da724058ADC2174a9844412',
    },
    busd: {
        56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
        97: '0x322e4612792dcdd60a50f9802c823e7b51ef1e31',
    },
}