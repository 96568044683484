import useTitle from 'hooks/useTitle'
import { usePriceCakeBusd } from 'state/hooks'

interface DocumentTitleProps {
    title ? : string
}



const DocumentTitle: React.FC < DocumentTitleProps > = ({ title }) => {
    const cakePriceUsd = usePriceCakeBusd()
    useTitle(`$${cakePriceUsd.toFixed(3)} - ${title}`)

    // const fhtnPriceUsd = useSelector((state: State) => state.prices.fhtnPrices)
    // useTitle(`$${fhtnPriceUsd.toFixed(3)} - ${title} ✌️`)
    return null
}

export default DocumentTitle