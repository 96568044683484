import { MenuEntry } from 'vontery-fishingtown-uikit'

const config: MenuEntry[] = [
    {
        label: 'Home',
        icon: 'HomeIcon',
        href: '/',
    },
    {
        label: 'Farms',
        icon: 'FarmIcon',
        href: '/farms',
    },
    {
        label: 'Lucky Draw',
        icon: 'TicketIcon',
        href: '/luckydraw',
    },
    {
        label: 'Get FHTN',
        icon: 'PortfolioIcon',
        href: 'https://swap.arken.finance/tokens/bsc/0x87be0b856960f57fb0104ef0922cd1dacbd37f64',
    },
    {
        label: 'Whitepaper',
        icon: 'MoreIcon',
        href: 'https://fishing-town-official.gitbook.io/fishing-town-whitepaper/',
    }
]

export default config
