import { createGlobalStyle } from 'styled-components'
import { FishingTownTheme } from 'vontery-fishingtown-uikit'

import vcrosdmono from '../assets/fonts/vcr_osd_mono_webfont.ttf'


declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends FishingTownTheme { }
}

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: vcrosdmono;
    src: url(${vcrosdmono});
  }
  * {
      font-family: 'vcrosdmono', sans-serif;
  }
  body {
    background: linear-gradient(180deg,#66d2ec,#1d6d86);
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: contain;

    img {
      height: auto;
      max-width: 100%;
    }
  }
`

export default GlobalStyle