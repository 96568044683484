import React from 'react'
import styled from 'styled-components'
import { Spinner,Image } from 'vontery-fishingtown-uikit'
import Page from './layout/Page'

const Wrapper = styled(Page)`
  display: flex;
  justify-content: center;
  align-items: center;
`

const PageLoader: React.FC = () => (
  <Wrapper>
    {/* <Image src="./images/spinner.png" alt="illustration" width={100} height={100} responsive /> */}
    {/* <Spinner /> */}
  </Wrapper>
)

export default PageLoader
