export const EN = { code: 'en', language: 'English' }
export const LA = { code: 'la', language: 'ລາວ' }


export const allLanguages = [
  EN,
  LA
  // AR,
  // CA,
  // ZHCN,
  // ZHTW,
  // CS,
  // DA,
  // DE,
  // NL,
  // FIL,
  // FI,
  // FR,
  // EL,
  // HI,
  // HU,
  // ID,
  // IT,
  // JA,
  // KO,
  // PTBR,
  // RO,
  // RU,
  // SR,
  // ESES,
  // SVSE,
  // TA,
  // TR,
  // UK,
  // VI,
]
