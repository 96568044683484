import BigNumber from 'bignumber.js'
import { kebabCase } from 'lodash'
import { useEffect, useMemo } from 'react'
import { Toast, toastTypes } from 'vontery-fishingtown-uikit'
import { useSelector, useDispatch } from 'react-redux'
import useRefresh from 'hooks/useRefresh'
import { getWeb3NoAccount } from 'utils/web3'
import { fetchFarmsPublicDataAsync, fetchPoolsUserDataAsync, push as pushToast, remove as removeToast, clear as clearToast, setBlock } from './actions'
import { State, Farm, Pool } from './types'
import { QuoteToken } from '../config/constants/types'

const ZERO = new BigNumber(0)
const ONE = new BigNumber(1)

export const useFetchPublicData = () => {
    const dispatch = useDispatch()
    const { slowRefresh } = useRefresh()
    useEffect(() => {
        dispatch(fetchFarmsPublicDataAsync())
    }, [dispatch, slowRefresh])

    useEffect(() => {
        const web3 = getWeb3NoAccount()
        const interval = setInterval(async () => {
            const blockNumber = await web3.eth.getBlockNumber()
            dispatch(setBlock(blockNumber))
        }, 6000)

        return () => clearInterval(interval)
    }, [dispatch])
}

// Farms

export const useFarms = (): Farm[] => {
    const farms = useSelector((state: State) => state.farms.data)
    return farms
}

export const useFarmFromPid = (pid): Farm => {
    const farm = useSelector((state: State) => state.farms.data.find((f) => f.pid === pid))
    return farm
}

export const useFarmFromSymbol = (lpSymbol: string): Farm => {
    const farm = useSelector((state: State) => state.farms.data.find((f) => f.lpSymbol === lpSymbol))
    return farm
}

export const useFarmUser = (pid) => {
    const farm = useFarmFromPid(pid)

    return {
        allowance: farm.userData ? new BigNumber(farm.userData.allowance) : new BigNumber(0),
        tokenBalance: farm.userData ? new BigNumber(farm.userData.tokenBalance) : new BigNumber(0),
        stakedBalance: farm.userData ? new BigNumber(farm.userData.stakedBalance) : new BigNumber(0),
        earnings: farm.userData ? new BigNumber(farm.userData.earnings) : new BigNumber(0),
    }
}


// Pools

export const usePools = (account): Pool[] => {
    const { fastRefresh } = useRefresh()
    const dispatch = useDispatch()
    useEffect(() => {
        if (account) {
            dispatch(fetchPoolsUserDataAsync(account))
        }
    }, [account, dispatch, fastRefresh])

    const pools = useSelector((state: State) => state.pools.data)
    return pools
}

export const usePoolFromPid = (sousId): Pool => {
    const pool = useSelector((state: State) => state.pools.data.find((p) => p.sousId === sousId))
    return pool
}

// Prices

export const usePriceBnbBusd = (): BigNumber => {
    const pid = 3 // BNB-BUSD LP
    const farm = useFarmFromPid(pid)
    return farm.tokenPriceVsQuote ? new BigNumber(farm.tokenPriceVsQuote) : ZERO
}

export const usePriceCakeBusd = (): BigNumber => usePriceVonUsd()

export const usePriceVonUsd = (): BigNumber => {
    const pid = 1 // FHTN-BUSD LP
    const farm = useFarmFromPid(pid)
    return farm.tokenPriceVsQuote ? new BigNumber(farm.tokenPriceVsQuote) : ZERO
}

export const useTotalValue = (): BigNumber => {
    const farms = useFarms();
    const bnbPrice = usePriceBnbBusd();
    const cakePrice = usePriceCakeBusd();
    let value = new BigNumber(0);
    for (let i = 0; i < farms.length; i++) {
        const farm = farms[i];
        if (farm.lpTotalInQuoteToken) {
            let val
            if (farm.quoteTokenSymbol === QuoteToken.BNB) {
                val = (bnbPrice.times(farm.lpTotalInQuoteToken));
            } else if (farm.quoteTokenSymbol === QuoteToken.FHTN) {
                val = (cakePrice.times(farm.tokenAmount));
            } else {
                val = farm.lpTotalInQuoteToken
            }
            value = value.plus(val);
            console.warn(i,value.toString(10));
        } 
    }
    return value;
}

// // Toasts
export const useToast = () => {
    const dispatch = useDispatch()
    const helpers = useMemo(() => {
        const push = (toast: Toast) => dispatch(pushToast(toast))

        return {
            toastError: (title: string, description ? : string) => push({ id: kebabCase(title), type: toastTypes.DANGER, title, description }),
            toastInfo: (title: string, description ? : string) => push({ id: kebabCase(title), type: toastTypes.INFO, title, description }),
            toastSuccess: (title: string, description ? : string) => push({ id: kebabCase(title), type: toastTypes.SUCCESS, title, description }),
            toastWarning: (title: string, description ? : string) => push({ id: kebabCase(title), type: toastTypes.WARNING, title, description }),
            push,
            remove: (id: string) => dispatch(removeToast(id)),
            clear: () => dispatch(clearToast()),
        }
    }, [dispatch])

    return helpers
}