import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const farms: FarmConfig[] = [
  {
    pid: 0,
    risk: 0,
    lpSymbol: 'FHTN',
    lpAddresses: {
      97: '0xa322d237e20e9554Fe84A60DAe3462E41F940162', // FHTN
      56: '0x87be0b856960f57fb0104ef0922cd1dacbd37f64', // FHTN
    },
    tokenSymbol: 'FHTN',
    tokenAddresses: {
      97: '0xa322d237e20e9554Fe84A60DAe3462E41F940162', // FHTN
      56: '0x87be0b856960f57fb0104ef0922cd1dacbd37f64', // FHTN
    },
    quoteTokenSymbol: QuoteToken.FHTN,
    quoteTokenAdresses: contracts.fhtn,
    isMasterChef:true,
    isTokenOnly:true
  },
  
  {
    pid: 1,
    risk: 3,
    lpSymbol: 'FHTN-BUSD',
    lpAddresses: {
      97: '0x200f24f81f30ea8d97d90ccc09e4d2debaf81abc',
      56: '0xdc31f7d9ab7c53b50eb1f400d5d3ea61c02c43bc',
    },
    tokenSymbol: 'FHTN',
    tokenAddresses: {
      97: '0xa322d237e20e9554Fe84A60DAe3462E41F940162',
      56: '0x87be0b856960f57fb0104ef0922cd1dacbd37f64',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
    isMasterChef:true,
    isTokenOnly:false
  },
  {
    pid: 2,
    risk: 5,
    lpSymbol: 'FHTN-BNB',
    lpAddresses: {
      97: '0x307188b928e425f7c63f189a032c3268a1e583f6',
      56: '0x1fE76D16F5Ed92F843d5d49dcF2aaEc23d4682CD',
    },
    tokenSymbol: 'FHTN',
    tokenAddresses: {
      97: '0x1b9E9F0DCFaD211D68404a09aF612e4b89649CCe',
      56: '0x87be0b856960f57fb0104ef0922cd1dacbd37f64',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
    isMasterChef:true,
    isTokenOnly:false
  },
  {
    pid: 3,
    risk: 3,
    lpSymbol: 'BNB-BUSD',
    lpAddresses: {
      97: '0x200f24f81f30ea8d97d90ccc09e4d2debaf81abc',
      56: '0x58f876857a02d6762e0101bb5c46a8c1ed44dc16',
    },
    tokenSymbol: 'BNB',
    tokenAddresses: {
      97: '0xa322d237e20e9554Fe84A60DAe3462E41F940162',
      56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
    isMasterChef:true,
    isTokenOnly:false
  }
]

export default farms
